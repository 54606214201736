import { Action } from '@ngrx/store';
import { StateError } from '..';
import {
  IConnection,
  IConnectionWithScopedCouplings,
  IConsentForCoupling,
  ICoupling,
  IDataConnectInboundAuth,
  IDataConnectInboundContainerPut,
  IDataConnectInboundContainerPutType,
  IDataConnectInboundCouplingList,
  IDataConnectInboundEquipmentList, SortedConnections,
} from '@app/shared/models/connections.model';

export const CONNECTIONS = '[Connections] Get Connections';
export const CONNECTIONS_SUCCESS = '[Connections] Get Connection Success';
export const CONNECTIONS_FAILURE = '[Connections] Get Connection Failure';
export const CONNECTIONS_COMPLETE = '[Connections] Get Connection Complete';

export const CONNECTION = '[Connection] Get Connections';
export const CONNECTION_SUCCESS = '[Connection] Get Connection Success';
export const CONNECTION_FAILURE = '[Connection] Get Connection Failure';
export const CONNECTION_COMPLETE = '[Connection] Get Connection Complete';

export const CONNECTIONS_PARTNER_IMAGES_LOADED_SUCCESS = '[Connections] Get Connection Partner Images Success';
export const CONNECTIONS_PARTNER_IMAGES_LOADED_FAILURE = '[Connections] Get Connection Partner Images Failure';
export const CONNECTIONS_PARTNER_IMAGES_LOADED_COMPLETE = '[Connections] Get Connection Partner Images Complete';

export const CONNECTION_PARTNER_IMAGE_LOADED_SUCCESS = '[Connection] Get Connection Partner Images Success';
export const CONNECTION_PARTNER_IMAGE_LOADED_FAILURE = '[Connection] Get Connection Partner Images Failure';
export const CONNECTION_PARTNER_IMAGE_LOADED_COMPLETE = '[Connection] Get Connection Partner Images Complete';

export const REVOKE_COUPLING_OF_CONNECTION = '[Connection] Revoke Coupling';
export const REVOKE_COUPLING_OF_CONNECTION_SUCCESS = '[Connection] Create Connection Success';
export const REVOKE_COUPLING_OF_CONNECTION_FAILURE = '[Connection] Create Connection Failure';
export const REVOKE_COUPLING_OF_CONNECTION_COMPLETE = '[Connection] Create Connection Complete';

export const COUPLING_CONSENT = '[Connection] Get Content of a Coupling';
export const COUPLING_CONSENT_SUCCESS = '[Connection] Get Content of a Coupling Success';
export const COUPLING_CONSENT_FAILURE = '[Connection] Get Content of a Coupling Failure';
export const COUPLING_CONSENT_COMPLETE = '[Connection] Get Content of a Coupling Complete';
export const CONNECTION_SELECTED = '[Connection] Show a specific connection';

export const DATACONNECT_INBOUND_COUPLING_AUTH = '[Connection] Authorize DataConnect Inbound Coupling';
export const DATACONNECT_INBOUND_COUPLING_AUTH_SUCCESS = '[Connection] Authorize DataConnect Inbound Coupling Success';
export const DATACONNECT_INBOUND_COUPLING_AUTH_FAILURE = '[Connection] Authorize DataConnect Inbound Coupling Failure';
export const DATACONNECT_INBOUND_COUPLING_AUTH_COMPLETE = '[Connection] Authorize DataConnect Inbound Coupling Complete';

export const DATACONNECT_FETCH_EQUIPMENT = '[Connection] Fetch Equipment';
export const DATACONNECT_FETCH_EQUIPMENT_SUCCESS = '[Connection] Fetch Equipment Success';
export const DATACONNECT_FETCH_EQUIPMENT_FAILURE = '[Connection] Fetch Equipment Failure';
export const DATACONNECT_FETCH_EQUIPMENT_COMPLETE = '[Connection] Fetch Equipment Complete';

export const DATACONNECT_PUT_CONTAINER_CREATE = '[Connection] Put Container Create';
export const DATACONNECT_PUT_CONTAINER_CREATE_SUCCESS = '[Connection] Put Container Create Success';
export const DATACONNECT_PUT_CONTAINER_CREATE_FAILURE = '[Connection] Put Container Create Failure';
export const DATACONNECT_PUT_CONTAINER_CREATE_COMPLETE = '[Connection] Put Container Create Complete';

export const DATACONNECT_PUT_CONTAINER_UPDATE = '[Connection] Put Container Update';
export const DATACONNECT_PUT_CONTAINER_UPDATE_SUCCESS = '[Connection] Put Container Update Success';
export const DATACONNECT_PUT_CONTAINER_UPDATE_FAILURE = '[Connection] Put Container Update Failure';
export const DATACONNECT_PUT_CONTAINER_UPDATE_COMPLETE = '[Connection] Put Container Update Complete';

export const DATACONNECT_DELETE_COUPLING = '[Connection] Delete Coupling';
export const DATACONNECT_DELETE_COUPLING_SUCCESS = '[Connection] Delete Coupling Success';
export const DATACONNECT_DELETE_COUPLING_FAILURE = '[Connection] Delete Coupling Failure';
export const DATACONNECT_DELETE_COUPLING_COMPLETE = '[Connection] Delete Coupling Complete';

export const DATACONNECT_GET_COUPLINGS = '[Connection] Get Couplings';
export const DATACONNECT_GET_COUPLINGS_SUCCESS = '[Connection] Get Couplings Success';
export const DATACONNECT_GET_COUPLINGS_FAILURE = '[Connection] Get Couplings Failure';
export const DATACONNECT_GET_COUPLINGS_COMPLETE = '[Connection] Get Couplings Complete';

export const RESET_CONNECTIONS = '[Connection] Reset Connections';

export class ConnectionList implements Action {
  readonly type = CONNECTIONS;

  constructor(public payload: {languageCode: string}) {}
}

export class ConnectionsSuccess implements Action {
  readonly type = CONNECTIONS_SUCCESS;

  constructor(public payload: SortedConnections) {}
}

export class ConnectionsFailure implements Action {
  readonly type = CONNECTIONS_FAILURE;

  constructor(public payload: StateError) {}
}

export class ConnectionsComplete implements Action {
  readonly type = CONNECTIONS_COMPLETE;
}

export class Connection implements Action {
  readonly type = CONNECTION;

  constructor(public payload: { id: string, languageCode: string }) {}
}

export class ConnectionSuccess implements Action {
  readonly type = CONNECTION_SUCCESS;

  constructor(public payload: IConnectionWithScopedCouplings) {}
}

export class ConnectionFailure implements Action {
  readonly type = CONNECTION_FAILURE;

  constructor(public payload: StateError) {}
}

export class ConnectionComplete implements Action {
  readonly type = CONNECTION_COMPLETE;
}

export class ConnectionPartnerImagesLoadedSuccess implements Action {
  readonly type = CONNECTIONS_PARTNER_IMAGES_LOADED_SUCCESS;

  constructor(public payload: Map<string, string>) {}
}

export class ConnectionPartnerImagesLoadedFailure implements Action {
  readonly type = CONNECTIONS_PARTNER_IMAGES_LOADED_FAILURE;

  constructor(public payload: StateError) {}
}

export class ConnectionPartnerImagesLoadedCompleted implements Action {
  readonly type = CONNECTIONS_PARTNER_IMAGES_LOADED_COMPLETE;

  constructor() {}
}

export class ConnectionPartnerImageLoadedSuccess implements Action {
  readonly type = CONNECTION_PARTNER_IMAGE_LOADED_SUCCESS;

  constructor(public payload: any) {}
}

export class ConnectionPartnerImageLoadedFailure implements Action {
  readonly type = CONNECTION_PARTNER_IMAGE_LOADED_FAILURE;

  constructor(public payload: StateError) {}
}

export class ConnectionPartnerImageLoadedCompleted implements Action {
  readonly type = CONNECTION_PARTNER_IMAGE_LOADED_COMPLETE;

  constructor() {}
}

export class RevokeCoupling implements Action {
  readonly type = REVOKE_COUPLING_OF_CONNECTION;

  constructor(public payload: { coupling: ICoupling; connection: IConnection, languageCode: string }) {}
}

export class RevokeCouplingSuccess implements Action {
  readonly type = REVOKE_COUPLING_OF_CONNECTION_SUCCESS;
}

export class RevokeCouplingFailure implements Action {
  readonly type = REVOKE_COUPLING_OF_CONNECTION_FAILURE;

  constructor(public payload: StateError) {}
}

export class RevokeCouplingComplete implements Action {
  readonly type = REVOKE_COUPLING_OF_CONNECTION_COMPLETE;
}

export class CouplingConsent implements Action {
  readonly type = COUPLING_CONSENT;

  constructor(public payload: { connectionId: string; coupling: ICoupling }) {}
}

export class CouplingConsentSuccess implements Action {
  readonly type = COUPLING_CONSENT_SUCCESS;
  constructor(public payload: IConsentForCoupling) {}
}

export class CouplingConsentFailure implements Action {
  readonly type = COUPLING_CONSENT_FAILURE;

  constructor(public payload: StateError) {}
}

export class CouplingConsentComplete implements Action {
  readonly type = COUPLING_CONSENT_COMPLETE;
}

export class ConnectionSelected implements Action {
  readonly type = CONNECTION_SELECTED;

  constructor(public payload: { connectionId: string }) {}
}

export class DataConnectGetCouplings implements Action {
  readonly type = DATACONNECT_GET_COUPLINGS;

  constructor(public payload: { connectionId: string }) {}
}

export class DataConnectGetCouplingsSuccess implements Action {
  readonly type = DATACONNECT_GET_COUPLINGS_SUCCESS;

  constructor(public payload: IDataConnectInboundCouplingList) {}
}

export class DataConnectGetCouplingsFailure implements Action {
  readonly type = DATACONNECT_GET_COUPLINGS_FAILURE;

  constructor(public payload: StateError) {}
}

export class DataConnectGetCouplingsComplete implements Action {
  readonly type = DATACONNECT_GET_COUPLINGS_COMPLETE;
}

export class DataConnectInboundCouplingAuth implements Action {
  readonly type = DATACONNECT_INBOUND_COUPLING_AUTH;

  constructor(
    public payload: {
      connectionId: string;
      state?: string;
      code?: string;
      error?: string;
      errorDescription?: string;
    }
  ) {}
}

export class DataConnectInboundCouplingAuthSuccess implements Action {
  readonly type = DATACONNECT_INBOUND_COUPLING_AUTH_SUCCESS;

  constructor(public payload: IDataConnectInboundAuth) {}
}

export class DataConnectInboundCouplingAuthFailure implements Action {
  readonly type = DATACONNECT_INBOUND_COUPLING_AUTH_FAILURE;

  constructor(public payload: StateError) {}
}

export class DataConnectInboundCouplingAuthComplete implements Action {
  readonly type = DATACONNECT_INBOUND_COUPLING_AUTH_COMPLETE;
}

export class DataConnectFetchEquipment implements Action {
  readonly type = DATACONNECT_FETCH_EQUIPMENT;

  constructor(public payload: { connectionId: string; couplingId: string; containerId: string }) {}
}

export class DataConnectFetchEquipmentSuccess implements Action {
  readonly type = DATACONNECT_FETCH_EQUIPMENT_SUCCESS;

  constructor(public payload: IDataConnectInboundEquipmentList) {}
}

export class DataConnectFetchEquipmentFailure implements Action {
  readonly type = DATACONNECT_FETCH_EQUIPMENT_FAILURE;

  constructor(public payload: StateError) {}
}

export class DataConnectFetchEquipmentComplete implements Action {
  readonly type = DATACONNECT_FETCH_EQUIPMENT_COMPLETE;
}

export class DataConnectPutContainerCreate implements Action {
  readonly type = DATACONNECT_PUT_CONTAINER_CREATE;

  public payload: {
    connectionId: string;
    couplingId: string;
    putContainer: IDataConnectInboundContainerPut;
    type?: IDataConnectInboundContainerPutType;
  };

  constructor(payload: {
    connectionId: string;
    couplingId: string;
    putContainer: IDataConnectInboundContainerPut;
    type?: IDataConnectInboundContainerPutType;
  }) {
    this.payload = {
      ...payload,
      type: payload.type ?? 'create',
    };
  }
}

export class DataConnectPutContainerCreateSuccess implements Action {
  readonly type = DATACONNECT_PUT_CONTAINER_CREATE_SUCCESS;
}

export class DataConnectPutContainerCreateFailure implements Action {
  readonly type = DATACONNECT_PUT_CONTAINER_CREATE_FAILURE;

  constructor(public payload: StateError) {}
}

export class DataConnectPutContainerCreateComplete implements Action {
  readonly type = DATACONNECT_PUT_CONTAINER_CREATE_COMPLETE;
}

export class DataConnectPutContainerUpdate implements Action {
  readonly type = DATACONNECT_PUT_CONTAINER_UPDATE;

  public payload: {
    connectionId: string;
    couplingId: string;
    putContainer: IDataConnectInboundContainerPut;
    type?: IDataConnectInboundContainerPutType;
  };

  constructor(payload: {
    connectionId: string;
    couplingId: string;
    putContainer: IDataConnectInboundContainerPut;
    type?: IDataConnectInboundContainerPutType;
  }) {
    this.payload = {
      ...payload,
      type: payload.type ?? 'update',
    };
  }
}

export class DataConnectPutContainerUpdateSuccess implements Action {
  readonly type = DATACONNECT_PUT_CONTAINER_UPDATE_SUCCESS;

  public payload: {
    couplingId: string;
  };

  constructor(payload: { couplingId: string }) {
    this.payload = {
      ...payload,
    };
  }
}

export class DataConnectPutContainerUpdateFailure implements Action {
  readonly type = DATACONNECT_PUT_CONTAINER_UPDATE_FAILURE;

  constructor(public payload: StateError) {}
}

export class DataConnectPutContainerUpdateComplete implements Action {
  readonly type = DATACONNECT_PUT_CONTAINER_UPDATE_COMPLETE;
}

export class DataConnectDeleteCoupling implements Action {
  readonly type = DATACONNECT_DELETE_COUPLING;

  constructor(public payload: { connectionId: string; couplingId: string }) {}
}

export class DataConnectDeleteCouplingSuccess implements Action {
  readonly type = DATACONNECT_DELETE_COUPLING_SUCCESS;
}

export class DataConnectDeleteCouplingFailure implements Action {
  readonly type = DATACONNECT_DELETE_COUPLING_FAILURE;

  constructor(public payload: StateError) {}
}

export class DataConnectDeleteCouplingComplete implements Action {
  readonly type = DATACONNECT_DELETE_COUPLING_COMPLETE;
}

export class ResetConnections implements Action{
  readonly type = RESET_CONNECTIONS;
}

export type Actions =
  | ConnectionList
  | ConnectionsSuccess
  | ConnectionsFailure
  | ConnectionsComplete
  | Connection
  | ConnectionSuccess
  | ConnectionFailure
  | ConnectionComplete
  | ConnectionPartnerImagesLoadedSuccess
  | ConnectionPartnerImagesLoadedFailure
  | ConnectionPartnerImagesLoadedCompleted
  | ConnectionPartnerImageLoadedSuccess
  | ConnectionPartnerImageLoadedFailure
  | ConnectionPartnerImageLoadedCompleted
  | RevokeCoupling
  | RevokeCouplingSuccess
  | RevokeCouplingFailure
  | RevokeCouplingComplete
  | CouplingConsent
  | CouplingConsentSuccess
  | CouplingConsentFailure
  | CouplingConsentComplete
  | ConnectionSelected
  | DataConnectInboundCouplingAuth
  | DataConnectInboundCouplingAuthSuccess
  | DataConnectInboundCouplingAuthFailure
  | DataConnectInboundCouplingAuthComplete
  | DataConnectFetchEquipment
  | DataConnectFetchEquipmentSuccess
  | DataConnectFetchEquipmentFailure
  | DataConnectFetchEquipmentComplete
  | DataConnectPutContainerCreate
  | DataConnectPutContainerCreateSuccess
  | DataConnectPutContainerCreateFailure
  | DataConnectPutContainerCreateComplete
  | DataConnectPutContainerUpdate
  | DataConnectPutContainerUpdateSuccess
  | DataConnectPutContainerUpdateFailure
  | DataConnectPutContainerUpdateComplete
  | DataConnectDeleteCoupling
  | DataConnectDeleteCouplingSuccess
  | DataConnectDeleteCouplingFailure
  | DataConnectDeleteCouplingComplete
  | DataConnectGetCouplings
  | DataConnectGetCouplingsSuccess
  | DataConnectGetCouplingsFailure
  | DataConnectGetCouplingsComplete
  | ResetConnections;
